import classNames from 'classnames';
import React from 'react';
import desktopImage from '../../images/blog/cta-ui-desktop.svg';
import mobileImage from '../../images/blog/cta-ui-mobile.svg';
import howSuccessIsWrittenImage from '../../images/blog/how-success-is-written-cta.png';
import Button from '../ui/Button';
import Link from '../ui/Link';
import styles from './BlogCTA.module.scss';

type BlogCTAProps = {
  anchorClassName?: string;
  type?: 'how-success-is-written' | 'knowledge-hub';
};

const BlogCTA = ({ anchorClassName, type = 'knowledge-hub' }: BlogCTAProps) => {
  return type === 'knowledge-hub' ? (
    <Link
      to="/"
      className={classNames(styles.container, anchorClassName)}
      track={{ label: 'blog-inline-cta' }}
    >
      <picture>
        <source media="(max-width: 480px)" srcSet={mobileImage} />
        <source srcSet={desktopImage} />
        <img src={desktopImage} alt="Get Started with Slab" />
      </picture>
    </Link>
  ) : (
    <Link
      to="/guides/how-success-is-written"
      className={classNames(styles.howSuccessIsWritten, anchorClassName)}
      track={{ label: 'how-success-is-written-blog-cta' }}
    >
      <div className={styles.copy}>
        <div className={styles.wrap}>
          <h2 className={styles.heading}>How Success is Written</h2>
          <p>Learn how top companies are using writing to fuel innovation</p>
          <Button primary small>
            Download eBook
          </Button>
        </div>
      </div>
      <img
        src={howSuccessIsWrittenImage}
        alt="A sketchbook of various company logos"
      />
    </Link>
  );
};

export default BlogCTA;
