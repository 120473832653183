import { BlogAuthor, FileEdges, IntroYamlEdges } from '../types';
import toFileMap from './toFileMap';

export interface AuthorMap {
  [slug: string]: BlogAuthor;
}

interface AuthorResponse {
  data: {
    members: { edges: IntroYamlEdges };
    pictures: { edges: FileEdges };
  };
}

const toAuthorMap: (response: AuthorResponse) => AuthorMap = ({
  data: {
    members: { edges: memberEdges },
    pictures: { edges: pictureEdges },
  },
}) => {
  const picturesMap = toFileMap(pictureEdges, 'name');

  return memberEdges.reduce(
    (
      memo,
      {
        node: {
          name,
          social,
          handle,
          fields: { slug },
        },
      },
    ) => ({
      ...memo,
      [slug]: {
        name,
        pictureURL: picturesMap[slug],
        social,
        handle,
      },
    }),
    {},
  );
};

export default toAuthorMap;
