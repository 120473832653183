import {
  BlogMarkdownRemark,
  BlogMarkdownRemarkEdges,
  BlogPost,
  FileEdges,
  IntroYamlEdges,
} from '../types';
import toAuthorMap, { AuthorMap } from './toAuthorMap';

export interface PostQueryResponse {
  data: {
    post: BlogMarkdownRemark;
    members: { edges: IntroYamlEdges };
    pictures: { edges: FileEdges };
  };
}

export interface PostsQueryResponse {
  data: {
    posts: { edges: BlogMarkdownRemarkEdges };
    members: { edges: IntroYamlEdges };
    pictures: { edges: FileEdges };
  };
}

const convertPost: (
  post: BlogMarkdownRemark,
  authorMap: AuthorMap,
) => BlogPost = (post, authorMap) => {
  const {
    html,
    htmlAst,
    excerpt,
    frontmatter: {
      title,
      date,
      edited,
      author,
      category,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hide,
      summary,
      cover,
      gradient,
      citations,
    },
    fields: { slug },
  } = post;

  return {
    author: authorMap[author],
    category,
    coverURL: cover?.publicURL,
    coverFluid: cover?.childImageSharp?.fluid,
    coverFixed: cover?.childImageSharp?.fixed,
    date,
    edited,
    excerpt,
    gradient,
    hide,
    html,
    htmlAst,
    slug,
    summary,
    title,
    citations,
  };
};

export const fullPost: (response: PostQueryResponse) => BlogPost = (
  response,
) => {
  const authorMap = toAuthorMap(response);

  return convertPost(response.data.post, authorMap);
};

export const fullPosts: (response: PostsQueryResponse) => BlogPost[] = (
  response,
) => {
  const authorMap = toAuthorMap(response);
  const { posts } = response.data;

  return posts
    ? posts.edges.map(({ node: post }) => convertPost(post, authorMap))
    : [];
};
