import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import styles from './SocialButtons.module.scss';

interface SocialButtonsProps {
  title: string;
  excerpt: string;
  path: string;
  summary?: string;
}

const iconSize = 30;
const SocialButtons: React.SFC<SocialButtonsProps> = ({
  title,
  excerpt,
  path,
  summary,
}) => {
  const description = summary || excerpt;

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { siteUrl },
        },
      }) => {
        const url = `${siteUrl}${path}`;
        const styleProps = {
          bgStyle: { fill: 'transparent' },
          iconFillColor: 'inherit',
        };

        return (
          <div className={styles.container}>
            {/* <RedditShareButton url={url} title={title}>
              <RedditIcon round size={iconSize} />
              <RedditShareCount url={url}>
                {count => renderShareCount(count)}
              </RedditShareCount>
            </RedditShareButton> */}
            <TwitterShareButton url={url} title={title}>
              {/* @ts-expect-error */}
              <TwitterIcon {...styleProps} size={iconSize} />
            </TwitterShareButton>
            {/* <GooglePlusShareButton url={url}>
              <GooglePlusIcon round size={iconSize} />
              <GooglePlusShareCount url={url}>
                {count => renderShareCount(count)}
              </GooglePlusShareCount>
            </GooglePlusShareButton> */}
            <FacebookShareButton url={url} quote={description}>
              {/* @ts-expect-error */}
              <FacebookIcon {...styleProps} size={iconSize} />
            </FacebookShareButton>
            <LinkedinShareButton url={url}>
              {/* @ts-expect-error */}
              <LinkedinIcon {...styleProps} size={iconSize} />
            </LinkedinShareButton>
            {/* <TelegramShareButton url={url}>
              <TelegramIcon round size={iconSize} />
            </TelegramShareButton> */}
          </div>
        );
      }}
    />
  );
};

export default SocialButtons;
