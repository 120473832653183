import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { BlogPost } from '../../types';
import { blogCategoryPath, postPath } from '../../utils/path';
import BlogGradient from './BlogGradient';
import styles from './BlogGrid.module.scss';
import BlogPostSummary from './BlogPostSummary';

interface BlogGridProps {
  posts: BlogPost[];
}

const BlogGrid: React.SFC<BlogGridProps> = ({ posts }) => (
  <div className={styles.blogGrid}>
    {posts.map((post) => {
      const link = postPath(post.slug);

      return (
        <div key={post.slug} className={styles.item}>
          <Link className={styles.imageContainer} to={link}>
            {post.gradient ? (
              <BlogGradient post={post} />
            ) : (
              post.coverFluid && (
                <GatsbyImage fluid={post.coverFluid} loading="eager" />
              )
            )}
          </Link>
          <div className={styles.text}>
            <div className={styles.category}>
              <Link to={blogCategoryPath(post.category)}>{post.category}</Link>
            </div>
            <Link to={link}>
              {!post.gradient && (
                <div className={styles.title}>{post.title}</div>
              )}
              <div className={styles.summary}>
                <BlogPostSummary post={post} />
              </div>
            </Link>
          </div>
        </div>
      );
    })}
  </div>
);

export default BlogGrid;
