import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import React from 'react';
import wrapTrack from '../../utils/wrapTrack';

interface LinkProps extends Omit<GatsbyLinkProps<unknown>, 'ref'> {
  track?: unknown;
}

const Link: React.SFC<LinkProps> = ({
  onClick,
  children,
  to,
  track,
  ...props
}) => (
  <GatsbyLink to={to} onClick={wrapTrack(track, onClick)} {...props}>
    {children}
  </GatsbyLink>
);

export default Link;
